<template>
  <div class="companyComfireIndex-container">
    <router-view/>
  </div>
  
</template>

<script>
export default {
  name: 'companyComfireIndex',
  components: {
  }
}

</script>

<style>
 
</style>
